import React, { useState } from "react";
import styled from "styled-components";

import { useModal } from "../components/useModal";

import { Modal } from "../components/site";
import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Image from "../components/image";
import Seo from "../components/seo";

const StaffList = styled(Row)`
  --item-gap: 80px;
  align-items: stretch;

  width: calc(100% + var(--item-gap));

  > div {
    width: calc((100% / 3) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const TeamCard = styled(Box)`
  padding: 20px;
  cursor: pointer;
  border-radius: 10px;
  transition: box-shadow 0.25s;

  p {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  }
`;

const AboutModal = styled(Box)`
  padding: 40px;
`;

const Staff = (props) => {
  const [itemModalOpen, setItemModalOpen] = useModal();
  const [modalContent, setModalContent] = useState([]);

  const handleModal = function(person) {
    setItemModalOpen(true);
    setModalContent(person);
  };

  const staff = [
    {
      image: "Bruce.jpg",
      name: "Bruce Simms",
      role: "Business Partner / Owner",
      title: "Technical Director – Geotechnical",
      qualification:
        "B.Sc (Tech) Earth Sciences, Waikato University | M.Sc Earth Science & Technology, Waikato University (Hons) | CPEng (Geotechnical)",
      bio: [
        <p>
          Over the past 20 plus years, Bruce has developed a wide range of
          geotechnical design experience within the land development and
          infrastructure sectors including site investigations, geotechnical
          design and project management services. He enjoys connecting with
          people and growing strong robust relationships.
        </p>,
        <p>
          This has included geotechnical design and reporting on horizontal
          infrastructure, highways, commercial, industrial and large-scale
          subdivision developments within complex geological settings,
          earthworks specifications, retaining wall design, stability
          assessments and contract management.
        </p>,
        <p>
          He has also had experience within project management; specifically
          risk management, a comprehensive schedule of qualities that reflect
          the construction methodology and delivering projects on time and
          within a specified budget.
        </p>,
      ],
    },
    {
      image: "Shahin.jpg",
      name: "Shahin Ghanooni",
      role: "Business Partner / Owner",
      title: "Technical Director – Geotechnical",
      qualification:
        "RecEng PIC | RecEng DSAP and Producer Statement Author (Auckland Council) | CPEng | PhD in Civil Engineering Imperial College | MSc in soil Mech. and Engineering Seismology Imperial College | BEng in Civil engineering (first class honours) Imperial College",
      bio: [
        <p>
          Shahin studied at Imperial College, London where he received a BEng in
          Civil Engineering with First class honours. He continued his studies
          and received an MSc in soil mechanics and engineering seismology and a
          Phd in soil dynamics from Imperial college.
        </p>,
        <p>
          Shahin is highly experienced in design and analysis of slope
          stability, retaining walls, foundation design and treatment,
          liquefaction analysis and remediation, deep excavation, design of
          piled foundations and two and three-dimensional finite element
          analysis.
        </p>,
        <p>
          His experience also includes site investigation, preparation of
          Geotechnical reports based on the results of laboratory and field
          tests, construction supervision and selection of materials for
          construction.
        </p>,
        <p>
          Shahin has been involved with the design of Embankment Dams for
          hydroelectric schemes, design of flood alleviation schemes and stop
          banks with many years of experience in the site investigation,
          preparation of technical specifications and selection of materials for
          embankment construction, construction supervision and the design of
          embankment dams against seismic loading along with many different
          site-specific problems.
        </p>,
      ],
    },
    {
      image: "Janet.jpg",
      name: "Janet Duxfield",
      role: "Principal Geotechnical Engineer",
      title: "",
      qualification:
        "CPEng | BEng (Civil) (Hons) | MSc (Applied Geoscience)| Producer Statement Author, Auckland City Council | NZTA Accredited Engineers Representative",
      bio: [
        <p>
          Janet has over 23 years of experience in civil and geotechnical
          engineering on a variety of infrastructure, building and residential
          projects. She has developed profound technical skills and demonstrated
          competency in earthquake and geotechnical engineering, including site
          investigations, geotechnical assessment of seismic hazards,
          liquefaction, ground settlement and slope stability.
        </p>,
        <p>
          Janet’s design knowledge entails foundations, ground improvement,
          retaining structures, slope stabilization, stop banks and earthworks.
        </p>,
      ],
    },
    // {
    //   image: "Renee.jpg",
    //   name: "Annette de Chaves Cabadonga",
    //   role: "Principal Geotechnical Engineer",
    //   title: "",
    //   qualification:
    //     "CPEng (Geotechnical) | BE (Civil) (Hons) NZ | ME (Civil) University of Mindanao (PH)  | B.Sc.(Civil) University of Mindanao (PH)  | APEC Engineer",
    //   bio: [
    //     <p>
    //       Annette is a Principal Geotechnical Engineer within our ABuild Team.
    //       Annette contributes to the procurement, management and delivery of
    //       moderate to large-scale projects. Annette is adept in reporting,
    //       review and delivery of geotechnical assessment and design for clients’
    //       projects in a cost effective and timely manner.
    //     </p>,
    //     <p>
    //       Annette has worked on many projects throughout her career; including,
    //       but not limited to infrastructure development, stopbank construction,
    //       national and local authority road slip remediation, and high-risk
    //       environments such as substations and unstable slopes. She has 18 years
    //       of combined geotechnical consulting and civil engineering.
    //     </p>,
    //   ],
    // },
    // {
    //   image: "Royston.jpg",
    //   name: "Royston Davidge",
    //   role: "Senior Geotechnical Engineer",
    //   title: "",
    //   qualification: "CPEng | Dip Civil Engineering",
    //   bio: [
    //     <p>
    //       Royston is knowledgeable in Soil/foundation investigation and
    //       reporting, stability of hillsides for residential development and
    //       subdivisions
    //     </p>,
    //     <p>
    //       Royston leads the Manawatu region for the business and undertakes
    //       various stability assessments for the area and provides mitigation
    //       measures where land hazards are identified.
    //     </p>,
    //   ],
    // },
    {
      image: "Ben.jpg",
      name: "Ben Hillis",
      role: "Senior Structural Engineer",
      title: "",
      qualification: "NZCE (Civil)",
      bio: [
        <p>
          Ben has been with ABuild team for the since 2000 as a graduate
          structural engineer. Ben focuses on retaining wall structures on
          behalf of ABuild and its clients. He enjoys the challenges of
          Engineering, particularly in the Wellington region.
        </p>,
        <p>
          He is passionate about design solutions for slip retaining and
          thinking outside of the box. Having been involved in both structural
          and geotechnical design gives him a unique ability to combine both
          disciplines in the design.
        </p>,
        <p>
          Ben’s technical skills are inclusive of drafting AutoCAD including
          geotechnical, structural and drainage; geotechnical fieldwork and
          measure up of sites; site inspections and monitoring of construction
          works; design of retaining walls, foundations, structural and drainage
          works.
        </p>,
      ],
    },
    {
      image: "Jack.jpg",
      name: "Jack Milford",
      role: "Senior Geotechnical Engineer",
      title: "",
      qualification: "Bachelor of Science, 2011",
      bio: [
        <p>
          Jack has worked extensively on geotechnical projects in New Zealand,
          Australia and the Pacific Islands.
        </p>,
        <p>
          This includes geological mapping, complex site investigations,
          technical report writing and design (Desktop, Factual, Concept and
          Detailed Design), construction monitoring, liquefaction assessments
          and slope stability modelling.
        </p>,
        <p>
          His experience in large scale projects and similar linear
          infrastructure projects is invaluable in delivering an efficient
          investigation and providing detailed and relevant outputs.
        </p>,
      ],
    },
    {
      image: "Shaheel.jpg",
      name: "Shaheel Muzibur",
      role: "Intermediate Geotechnical Engineer",
      title: "",
      qualification: "BA Technology - Civil",
      bio: [
        <p>
          Having completed a Bachelor of Engineering Technology (Civil) at the
          end of 2018, Shaheel moved to Aotearoa New Zealand. He is ambitious,
          has a positive attitude and is hard working.
        </p>,
        <p>
          Shaheel regularly works on projects for ABuild’s clients undertaking
          geotechnical ground investigation, reporting, and analysing,
          investigating risks or geological hazards.
        </p>,
        <p>
          Shaheel is adept at building and maintaining relationships with
          contractors, clients and other professionals involved on the project
          from commencement through to completion of the project.
        </p>,
      ],
    },
    {
      image: "James.jpg",
      name: "James Coleman",
      role: "Senior CADD / Telecommunications Designer",
      title: "",
      qualification: "BA Three-Dimensional Design",
      bio: [
        <p>
          James has a wide range of experience within the telecommunications
          sector and in working with various clients. He is adept in undertaking
          site investigations, site design, construction monitoring, final
          inspections and QA.
        </p>,
        <p>
          James is an experienced designer and leads to assist the team to
          deliver to client’s requirements. James has a strong
          telecommunications and design background and as such has a very good
          understanding of all facets of the operational and strategic
          requirements of the telco sector.
        </p>,
        <p>
          James started his telco journey as a designer and drafter which has
          provided him with thorough and first-hand knowledge of processes and
          procedures to compliment his strong client and stakeholder
          relationship skills. He is well regarded for his hands-on approach and
          collaborative project leadership, creating “one team” project
          environments to successfully deliver timely and quality solutions.
        </p>,
      ],
    },
    // {
    //   image: "Joe.jpg",
    //   name: "Joe Kelly",
    //   role: "Senior Engineering Geologist",
    //   title: "",
    //   qualification:
    //     "Bachelor of Science (BSc) 2012 Geology, Physical Geography | AGS Landslide Risk Assessment | Geotechnical Rapid Assessor MBIE",
    //   bio: [
    //     <p>
    //       Joe is an engineering Geologist with 10 years’ experience based in the
    //       Central and Lower North Island of New Zealand.
    //     </p>,
    //     <p>
    //       He has a wide-ranging experience in team, project, and programme
    //       management across a variety of contexts and contracts; including
    //       emergency response works, collaborative maintenance contracts, capital
    //       works projects and multi-disciplinary projects.
    //     </p>,
    //     <p>
    //       His background encompasses retaining structures in transportation and
    //       infrastructure setting, including assessment of slope instability in a
    //       variety of settings using a number of assessment criteria to manage
    //       risk and achieve the desired outcomes.
    //     </p>,
    //   ],
    // },
    {
      image: "Renee.jpg",
      name: "Dean Mehlhopt",
      role: "Structural Drafter",
      title: "",
      qualification:
        "Dip. Eng (Mechanical) | NZ Cert PM | NZ Cert (Mech. Gen Engineering)",
      bio: [
        <p>
          Dean is new to the ABuild team and is a trade qualified engineer in
          engineering and design project roles. He is passionate about
          completing projects to a high level of quality to relevant AS/NZS and
          to the required specifications.
        </p>,
        <p>
          Dean will play an important role within the ABuild team and will use
          his array of skills to assist the team in project deliverables from
          site to design.
        </p>,
      ],
    },
    {
      image: "Renee.jpg",
      name: "Louise Frazer",
      role: "Graduate Geotechnical Engineer",
      title: "",
      qualification: "",
      bio: "",
    },
    // {
    //   image: "Renee.jpg",
    //   name: "Mayville Wootton",
    //   role: "Junior CAD Drafter",
    //   title: "",
    //   qualification: "",
    //   bio: "",
    // },
    {
      image: "Sarah.jpg",
      name: "Sarah Ghanooni",
      role: "Junior CAD Drafter",
      title: "",
      qualification: "",
      bio:
        "Sarah is currently completing her master’s at Victoria University in Landscape Architecture.  Sarah assists the CAD team with telecommunication and residential design delivery.",
    },
    {
      image: "Renee.jpg",
      name: "Jessica Kirk",
      role: "Receptionist / Project Coordinator",
      title: "",
      qualification: "",
      bio: "",
    },
    {
      image: "Emma.jpg",
      name: "Emma Figliola",
      role: "Business Development Lead | Office Manager",
      title: "",
      qualification: "",
      bio: [
        <p>
          Emma has worked within engineering consultancy for 15+ years. She is
          well versed in business development and project delivery. Emma’s
          experience has seen her writing succinct proposals for clients and
          managing client relationships on behalf of ABuild.
        </p>,
        <p>
          She has worked on large multi-disciplinary projects that have taken
          her overseas.
        </p>,
        <p>
          Emma prides herself on her internal and external relationships and
          ensuring clients are kept ‘in the loop’ at all times through the early
          stages of their project.
        </p>,
      ],
    },
  ];

  return (
    <Layout>
      <Seo title="Our Team" pathname={props.location.pathname} />
      <Container>
        <Wrapper>
          <h1 className="-textCenter">Our Team</h1>
          <StaffList isWrap justify="flex-start" className="-textCenter">
            {staff.map((item, i) => {
              return (
                <TeamCard gap={25} key={i} onClick={() => handleModal(item)}>
                  <Image
                    filename={item.image}
                    maxWidth={220}
                    alt={item.name + ` | ` + item.role}
                    rounded
                    centerImage
                  />
                  <Box gap={5}>
                    <h4 className="-fontBold">{item.name}</h4>
                    <p className="-fontBold" css={{ marginBottom: "5px" }}>
                      {item.role}
                    </p>
                    {item.title && <p>{item.title}</p>}
                    {item.qualification && (
                      <p css={{ color: "#999" }}>{item.qualification}</p>
                    )}
                  </Box>
                </TeamCard>
              );
            })}
          </StaffList>
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        handleClose={() => setItemModalOpen(false)}
      >
        <AboutModal gap={30}>
          <Image
            filename={modalContent.image}
            maxWidth={220}
            alt={modalContent.name + ` | ` + modalContent.role}
            rounded
            centerImage
          />
          <Box gap={5}>
            <h4 className="-fontBold">{modalContent.name}</h4>
            <p className="-fontBold" css={{ marginBottom: "5px" }}>
              {modalContent.role}
            </p>
            {modalContent.title && <p>{modalContent.title}</p>}
            {modalContent.qualification && (
              <p css={{ color: "#999" }}>{modalContent.qualification}</p>
            )}
          </Box>
          <div css={{ textAlign: "left" }}>{modalContent.bio}</div>
        </AboutModal>
      </Modal>
    </Layout>
  );
};

export default Staff;
